define("discourse/plugins/discourse-formatting-toolbar/discourse/initializers/formattingtlb-ui", ["exports", "discourse/lib/plugin-api", "discourse/components/d-editor"], function (_exports, _pluginApi, _dEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function initializePlugin(api) {
    var siteSettings = api.container.lookup('site-settings:main');

    if (siteSettings.formattingtlb_enabled) {
      api.onToolbarCreate(function (toolbar) {
        toolbar.addButton({
          id: "underline_ui_button",
          group: "fontStyles",
          icon: "underline",
          perform: function perform(e) {
            return e.applySurround('[u]', '[/u]', 'underline_ui_default_text');
          }
        });
      });
      api.onToolbarCreate(function (toolbar) {
        toolbar.addButton({
          id: "addimg_ui_button",
          group: "extras",
          icon: "far-image",
          perform: function perform(e) {
            return e.applySurround('[img]', '[/img]', 'addimg_ui_default_text');
          }
        });
      });
      api.onToolbarCreate(function (toolbar) {
        toolbar.addButton({
          id: "floatl_ui_button",
          group: "extras",
          icon: "indent",
          perform: function perform(e) {
            return e.applySurround('[floatl]', '[/floatl]', 'floatl_ui_default_text');
          }
        });
      });
      api.onToolbarCreate(function (toolbar) {
        toolbar.addButton({
          id: "left_ui_button",
          group: "extras",
          icon: "align-left",
          perform: function perform(e) {
            return e.applySurround('[left]', '[/left]', 'left_ui_default_text');
          }
        });
      });
      api.onToolbarCreate(function (toolbar) {
        toolbar.addButton({
          id: "center_ui_button",
          group: "extras",
          icon: "align-center",
          perform: function perform(e) {
            return e.applySurround('[center]', '[/center]', 'center_ui_default_text');
          }
        });
      });
      api.onToolbarCreate(function (toolbar) {
        toolbar.addButton({
          id: "right_ui_button",
          group: "extras",
          icon: "align-right",
          perform: function perform(e) {
            return e.applySurround('[right]', '[/right]', 'right_ui_default_text');
          }
        });
      });
      api.onToolbarCreate(function (toolbar) {
        toolbar.addButton({
          id: "justify_ui_button",
          group: "extras",
          icon: "align-justify",
          perform: function perform(e) {
            return e.applySurround('[justify]', '[/justify]', 'justify_ui_default_text');
          }
        });
      });
      api.onToolbarCreate(function (toolbar) {
        toolbar.addButton({
          id: "color_ui_button",
          group: "extras",
          icon: "palette",
          perform: function perform(e) {
            return e.applySurround('[color=#]', '[/color]', 'color_ui_default_text');
          }
        });
      });
      api.onToolbarCreate(function (toolbar) {
        toolbar.addButton({
          id: "size_ui_button",
          group: "extras",
          icon: "font",
          perform: function perform(e) {
            return e.applySurround('[size=4]', '[/size]', 'size_ui_default_text');
          }
        });
      });
    }
  }

  var _default = {
    name: "formattingtlb-ui",
    initialize: function initialize() {
      (0, _pluginApi.withPluginApi)("0.1", function (api) {
        return initializePlugin(api);
      });
    }
  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-formatting-toolbar/lib/discourse-markdown/formatting_bbcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;

  function replaceFontColor(text) {
    while (text !== (text = text.replace(/\[color=([^\]]+)\]((?:(?!\[color=[^\]]+\]|\[\/color\])[\S\s])*)\[\/color\]/ig, function (match, p1, p2) {
      return "<font color='".concat(p1, "'>").concat(p2, "</font>");
    }))) {
      ;
    }

    return text;
  }

  function replaceFontSize(text) {
    while (text !== (text = text.replace(/\[size=([^\]]+)\]((?:(?!\[size=[^\]]+\]|\[\/size\])[\S\s])*)\[\/size\]/ig, function (match, p1, p2) {
      return "<font size='".concat(p1, "'>").concat(p2, "</font>");
    }))) {
      ;
    }

    return text;
  }

  function wrap(tag, attr, callback) {
    return function (startToken, finishToken, tagInfo) {
      startToken.tag = finishToken.tag = tag;
      startToken.content = finishToken.content = '';
      startToken.type = 'bbcode_open';
      finishToken.type = 'bbcode_close';
      startToken.nesting = 1;
      finishToken.nesting = -1;
      startToken.attrs = [[attr, callback ? callback(tagInfo) : tagInfo.attrs._default]];
    };
  }

  function setupMarkdownIt(md) {
    var ruler = md.inline.bbcode.ruler;
    ruler.push('size', {
      tag: 'size',
      wrap: wrap('font', 'size')
    });
    ruler.push('color', {
      tag: 'color',
      wrap: wrap('font', 'color')
    });
    ruler.push('small', {
      tag: 'small',
      wrap: wrap('span', 'style', function () {
        return 'font-size:x-small';
      })
    });
    ruler.push('floatl', {
      tag: 'floatl',
      wrap: wrap('div', 'class', function () {
        return 'floatl';
      })
    });
    ruler.push('floatr', {
      tag: 'floatr',
      wrap: wrap('div', 'class', function () {
        return 'floatr';
      })
    });
    ruler.push('floatc', {
      tag: 'floatc',
      wrap: wrap('div', 'class', function () {
        return 'floatc';
      })
    });
    ruler.push('left', {
      tag: 'left',
      wrap: wrap('div', 'class', function () {
        return 'bbcodeleft';
      })
    });
    ruler.push('center', {
      tag: 'center',
      wrap: wrap('div', 'class', function () {
        return 'bbcodecenter';
      })
    });
    ruler.push('right', {
      tag: 'right',
      wrap: wrap('div', 'class', function () {
        return 'bbcoderight';
      })
    });
    ruler.push('justify', {
      tag: 'justify',
      wrap: wrap('div', 'class', function () {
        return 'bbcodejustify';
      })
    });
  }

  function setup(helper) {
    helper.allowList(['div.floatl', 'div.floatr', 'div.floatc', 'div.bbcodeleft', 'div.bbcodecenter', 'div.bbcoderight', 'div.bbcodejustify', 'font[color=*]', 'font[size=*]']);
    helper.allowList({
      custom: function custom(tag, name, value) {
        if (tag === 'span' && name === 'style') {
          return /^font-size:.*$/.exec(value);
        }
      }
    });
    helper.registerOptions(function (opts) {
      opts.features["formatting_bbcode"] = true;
    });

    if (helper.markdownIt) {
      helper.registerPlugin(setupMarkdownIt);
      return;
    }

    var builders = requirejs('pretty-text/engines/discourse-markdown/bbcode').builders;

    var _builders = builders(helper),
        register = _builders.register,
        replaceBBCode = _builders.replaceBBCode,
        rawBBCode = _builders.rawBBCode,
        replaceBBCodeParamsRaw = _builders.replaceBBCodeParamsRaw;

    replaceBBCode("small", function (contents) {
      return ['span', {
        'style': 'font-size:x-small'
      }].concat(contents);
    });
    replaceBBCode("floatl", function (contents) {
      return ['div', {
        'class': 'floatl'
      }].concat(contents);
    });
    replaceBBCode("floatr", function (contents) {
      return ['div', {
        'class': 'floatr'
      }].concat(contents);
    });
    replaceBBCode("floatc", function (contents) {
      return ['div', {
        'class': 'floatc'
      }].concat(contents);
    });
    replaceBBCode("left", function (contents) {
      return ['div', {
        'class': 'bbcodeleft'
      }].concat(contents);
    });
    replaceBBCode("center", function (contents) {
      return ['div', {
        'class': 'bbcodecenter'
      }].concat(contents);
    });
    replaceBBCode("right", function (contents) {
      return ['div', {
        'class': 'bbcoderight'
      }].concat(contents);
    });
    replaceBBCode("justify", function (contents) {
      return ['div', {
        'class': 'bbcodejustify'
      }].concat(contents);
    });
    helper.addPreProcessor(replaceFontColor);
    helper.addPreProcessor(replaceFontSize);
  }
});

